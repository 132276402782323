<template>
  <v-card
    class="mx-auto"
    max-width="450"
    min-width="350"
    outlined
    v-if="line.active"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <!-- <div class="overline mb-4">
          {{ line.type }}
        </div> -->
        <v-list-item-title
          class="mb-1 card-titol"
          v-html="line.title"
        ></v-list-item-title>
        <p class="body-2">
          {{ line.definition }}
        </p>
        <div v-if="line.logoUrl">
          <a @click="openPDF(line.pdfUrl)">
            <v-img :src="line.logoUrl" class="mt-5" contain width="180" />
          </a>
        </div>
      </v-list-item-content>
      <v-list-item-avatar
        v-if="line.iconColor"
        tile
        size="55"
        :style="'background-color:' + '#' + line.iconColor"
      >
        <h4 v-if="line.iconText" color="white--text" class="px-1">
          {{ line.iconText }}
        </h4>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        v-if="line.pdfUrl"
        @click="openPDF(line.pdfUrl)"
        outlined
        small
        text
        color="red darken-1"
      >
        <v-icon left>far fa-file-pdf</v-icon>
        Horari en PDF
      </v-btn>
      <v-spacer></v-spacer>
      <v-chip
        v-if="line.new"
        small
        label
        class="ma-2"
        color="red"
        text-color="white"
      >
        NOU
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "card-horari",

  data: () => ({
    // line: {}
  }),

  props: ["line"],

  mounted() {},

  methods: {
    openPDF(pdfUrl) {
      window.open(pdfUrl, "_blank");
    }
  }
};
</script>

<style scoped>
.card-titol {
  text-transform: uppercase;
  font-weight: 500;
}
</style>
